import config from "src/config";
import { ILanguage, ICountry } from "./locale";

type ICreateRedirectToHomeUrl = {
    language: ILanguage;
    country: ICountry;
    queryString?: string;
};

const BASE_URL = "/";

// FE API URLs
export const API_URL = `${BASE_URL}api`;

export const API_LOGIN_URL = `${API_URL}/login`;
export const API_REGISTER_URL = `${API_URL}/register`;
export const API_DECLARE_INTEREST_URL = `${API_URL}/declareinterest`;
export const API_CLOSE_STICKY_BANNER = `${API_URL}/content/unsubscribestickybanner`;
export const API_DETAIL_DOWNLOAD_URL = `${API_URL}/download/detail`;
export const API_DOWNLOAD_URL = `${API_URL}/download/search`;
export const API_DISCLAIMER_URL = `${API_URL}/disclaimer`;
export const API_ACCEPT_DISCLAIMER_MUTATION = `${API_DISCLAIMER_URL}/change`;

export const API_MODAL_URL = `${API_URL}/modal`;
export const API_COPY_ISIN_MODAL_URL = `${API_MODAL_URL}/copyisinmodal`;
export const API_AUTH_MODAL_URL = `${API_MODAL_URL}/authmodal`;
export const API_INVEST_NOW_MODAL_URL = `${API_MODAL_URL}/investnow`;
export const API_SAVINGS_PLAN_MODAL_URL = `${API_MODAL_URL}/savingsplanrebranding`;
export const API_DECLARE_INTEREST_MODAL_URL = `${API_MODAL_URL}/declareinterest`;
export const API_ONE_CLICK_NEWSLETTER_SUBSCRIPTION = `${API_URL}/notification/one-click`;

export const createApiWikifolioFeedUrl = (wikifolioId: string) => `${API_URL}/feed/wikifolio/${wikifolioId}`;

export const createApiLeverageProductContentUrl = (country: ICountry, language: ILanguage) =>
    `${API_URL}/umbraco/${country}/${language}/getleverageproductsdisclaimercontent`;
export const API_DISABLE_LEVERAGE_PRODUCTS_DISCLAMER_URL = `${API_URL}/user/disableleverageproductsdisclaimer`;
export const API_CHANGE_CURRENCY_MODE_URL = `${API_URL}/user/changecurrencymode`;
export const API_RESEND_REGISTRATION_MAIL_URL = `${API_URL}/user/resendregistrationmail`;
export const API_RESEND_DOUBLE_OPT_IN_MAIL_URL = `${API_URL}/user/resenduseroptin`;

const API_CHART_URL = `${API_URL}/chart`;
export const createApiPortfolioChartDataUrl = (wikifolioId: string) => `${API_CHART_URL}/${wikifolioId}/data`;
export const createApiWikifolioPriceIndexUrl = (wikifolioId: string) => `${API_CHART_URL}/${wikifolioId}/wikifolioindex`;
export const createApiWikifolioClosePriceIndexUrl = (wikifolioId: string) => `${API_CHART_URL}/${wikifolioId}/wikifolioclosepricehistory`;
export const createApiWikifolioBenchmarkUnderlyingUrl = (isin: string) => `${API_CHART_URL}/${isin}/underlyingclosepricehistory`;
export const createApiWikifolioBenchmarkIndexUrl = (isin: string) => `${API_CHART_URL}/${isin}/wikifoliobenchmarkindex`;

const API_WIKIFOLIO_URL = `${API_URL}/wikifolio`;
export const createApiWikifolioPortfolioUrl = (fullName: string) => `${API_WIKIFOLIO_URL}/${fullName}/portfolio`;
export const createApiWikifolioTradeHistoryUrl = (wikifolioId: string) => `${API_WIKIFOLIO_URL}/${wikifolioId}/tradehistory`;
export const createApiWikifolioPriceUrl = (wikifolioId: string) => `${API_WIKIFOLIO_URL}/${wikifolioId}/price`;

const API_WATCHLIST_URL = `${API_URL}/watchlist`;
const API_WATCHLIST_V2_URL = `${API_URL}/v2/watchlist`;
export const createApiAddWatchlistWikifoliosUrl = (wikifolioId: string) => `${API_WATCHLIST_URL}/${wikifolioId}/wikifolio`;
export const createApiRemoveWatchlistWikifoliosUrl = (wikifolioId: string) => `${API_WATCHLIST_URL}/${wikifolioId}/remove`;
export const API_WATCHLIST_WIKIFOLIOS_STATE_URL = `${API_WATCHLIST_URL}/state`;

export const API_WATCHLIST_UNDERLYINGS_URL = `${API_WATCHLIST_V2_URL}/underlyings`;
export const createApiRemoveWatchlistUnderlyingsUrl = (underlyingIsin: string) => `${API_WATCHLIST_UNDERLYINGS_URL}/${underlyingIsin}`;
export const API_WATCHLIST_UNDERLYINGS_STATE_URL = `${API_WATCHLIST_UNDERLYINGS_URL}/state`;

export const API_SEARCH_URL = `${API_URL}/extendedsearch`;
export const API_SEARCH_AUTOCOMPLETE_URL = config.searchAutoCompleteEndpoint;

const API_VOTE_URL = `${API_URL}/v2/vote`;
export const API_VOTE_USER_URL = `${API_VOTE_URL}/user`;

const API_FEED_V2_URL = `${API_URL}/v2/feed`;
const API_FEED_V3_URL = `${API_URL}/v3/feed`;
export const API_FEED_V2_USER_URL = `${API_FEED_V2_URL}/user`;
export const API_FEED_V3_USER_URL = `${API_FEED_V3_URL}/user`;
export const API_FEED_V3_NEWS_WIKIFOLIO = `${API_FEED_V3_URL}/news/wikifolio`;
export const API_FEED_V3_WIKIFOLIO = `${API_FEED_V3_URL}/wikifolio`;
export const API_FEED_V3_ALL_NEWS = `${API_FEED_V3_URL}/news/all`;
export const API_FEED_V3_NEWS_TRENDING = `${API_FEED_V3_URL}/news/trending`;
export const API_FEED_V3_NEWS_RANDOM_TRENDING = `${API_FEED_V3_URL}/news/random-trending`;

const API_UNDERLYING_URL = `${API_URL}/underlying`;
export const API_UNDERLYING_MENTIONED_URL = `${API_UNDERLYING_URL}/mentioned`;

export const API_COMMENT_URL = `${API_URL}/comment`;
export const API_COMMENT_UPDATE_URL = `${API_COMMENT_URL}/update`;
export const createDeleteCommentUrl = (commentId: string) => `${API_COMMENT_URL}/${commentId}`;

export const API_ARTICLE_PREVIEW_DATA_URL = `${API_COMMENT_URL}/articlepreviewdata`;

export const API_USER_REACTIONS_URL = `${API_URL}/userreactions`;

export const API_NEWS_TICKER_URL = `${API_URL}/newsticker`;

export const API_WATCHLISTED_WIKIFOLIOS_URL = `${BASE_URL}v3/watchlisted-wikifolios`;

// FE DYNAMIC URLs
const createDynamicUrl = (language: ILanguage, country: ICountry) => `${BASE_URL}dynamic/${language}/${country}`;

export const createDynamicTrackingUrl = (language: ILanguage, country: ICountry) => `${createDynamicUrl(language, country)}/tracking`;
export const createCustomMarketActivityUrl = (language: ILanguage, country: ICountry) =>
    `${createDynamicTrackingUrl(language, country)}/createcustommarketoactivity`;
export const createAddGenericActivityUrl = (language: ILanguage, country: ICountry) =>
    `${createDynamicTrackingUrl(language, country)}/addgenericactivity`;
export const createVisitDetailPageActivityUrl = (language: ILanguage, country: ICountry) =>
    `${createDynamicTrackingUrl(language, country)}/addvisitdetailpageactivity`;
export const createAddSmartFeedActivityUrl = (language: ILanguage, country: ICountry) =>
    `${createDynamicTrackingUrl(language, country)}/addsmartfeedactivity`;
export const createAddSmartFeedItemSeenActivityUrl = (language: ILanguage, country: ICountry) =>
    `${createDynamicTrackingUrl(language, country)}/addsmartfeeditemseenactivity`;
export const createConsentChangedUrl = (language: ILanguage, country: ICountry) => `${createDynamicTrackingUrl(language, country)}/consentupdated`;
export const createAddGenericActivityFromElysiumUrl = (language: ILanguage, country: ICountry) =>
    `${createDynamicTrackingUrl(language, country)}/addgenericactivityfromelysium`;
export const createChallengeFormSubmitUrl = (challengeId: string) => `${BASE_URL}v2/challenges/${challengeId}/participation`;

// Umbraco URLs
export const UMBRACO_SURFACE = "/umbraco/surface";
export const LOGOUT_URL = `${UMBRACO_SURFACE}/user/logout`;

// Server API URLs
export const PAGE_URL = "page";
export const EXTENDED_SEARCH_PAGE_URL = `${PAGE_URL}/search`;
export const UNDERLYING_OVERVIEW_PAGE_URL = `${PAGE_URL}/underlying/overview`;
export const FEED_PAGE_URL_V3 = `${PAGE_URL}/feed/v3`;
export const WATCHLISTS_PAGE = `${PAGE_URL}/watchlists`;
export const createAreaOverviewPageUrl = (slug: string) => `${PAGE_URL}/areaoverview/${slug}`;
export const createWikifolioDetailPageUrl = (symbol: string, adId?: string) =>
    `${PAGE_URL}/wikifolio/${symbol}${adId ? `?advertisementId=${adId}` : ""}`;
export const createNewsPageUrl = (slug: string) => `${PAGE_URL}/news/${slug}`;
export const createGeolocationUrl = (baseUrl?: string, ipAddress?: string) => `${baseUrl}/location/${ipAddress}`;
export const createChartImagePreviewUrl = (wikifolioId: string) => `${config.chartImagePreviewUrl}/${wikifolioId}/search`;

// Pages URLs
export const createLogoutFeedV3PageUrl = (language?: string, country?: string) => `${BASE_URL}${language}/${country}/feed/logout`;
export const createLogoutWatchlistsUrl = (language?: string, country?: string) => `${BASE_URL}${language}/${country}/watchlists/logout`;
export const createRedirectToHomeUrl = ({ language, country, queryString }: ICreateRedirectToHomeUrl) =>
    `${BASE_URL}${language}/${country}/home${queryString ?? ""}`;

export const createAllWikifoliosPageUrl = (language: ILanguage, country: ICountry) =>
    `/${language}/${country}/${language === "de" ? "alle-wikifolios/uebersicht" : "all-wikifolios/overview"}`;

export const createStockInsightsPageUnderlyingListUrl = (language: string, country: string) =>
    `/${language}/${country}/stock-insights#underlyinglist`;

export const createAggregatedReactionsPageUrl = (language: string, country: string) => `/${language}/${country}/l/marktreaktionen`;

// Third party SSO
export const createThirdPartyAuthUrl = (provider: string, returnUrl?: string) =>
    `${UMBRACO_SURFACE}/sso/login?provider=${provider}${returnUrl ? `&ReturnUrl=${returnUrl}` : ""}`;
