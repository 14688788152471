import { IDoubleOptInBanner, IStickyBanner, ILtsuBanner, IVerifyEmailBanner } from "./banners";
import { ILabelValue, ILink, IUser } from "./common";
import { EnumUserSegment } from "./common-enum";

export interface IHead {
    title: string;
    description: string;
    noIndex: boolean;
    version: string;
    rssFeedUrl: string;
    openSearchUrl: string;
    serverName: string;
    traceId: string | null;
    canonicalUrl: string;
    alternativeUrl: Record<string, string>;
    customImageUrl: string | null;
    chartImageUrl?: string;
    shortDescription?: string;
    ogTitle?: string;
    ogType?: string;
    isVwoEnabled?: boolean;
}

export interface IFooter {
    linkList: {
        title: string;
        links: ILink[];
    }[];
    currentIso: string;
    currencies: ILabelValue[];
    currentCountry: string;
    countries: ILabelValue[];
    email: ILink;
    phone: ILink;
    bottomLinkList: ILink[];
}

export enum EnumProviders {
    Facebook = "Facebook",
    Google = "Google",
    Apple = "Apple",
}

export interface IBanners {
    doubleOptInBanner: IDoubleOptInBanner | null;
    verifyEmailBanner: IVerifyEmailBanner | null;
    ltsuBanner: ILtsuBanner | null;
    stickyBanner: IStickyBanner | null;
}

export interface IGlobalRoutes {
    searchExtended: string;
    forgotPassword: string;
}

export type ServiceButtons = {
    email: string;
    phoneNumber: string;
    phoneNumberTrimmed: string;
} | null;

export interface IRegistrationPlatforms {
    wikifolioWeb: string;
    wikifolioMobileApp: string;
}

export interface IGlobal {
    currency: string;
    disclaimer: string | null;
    routes: IGlobalRoutes;
    serviceButtons: ServiceButtons;
    gtmData: IGtmData;
    welcomePageNodeId: number | null;
    loginProviders: EnumProviders[];
    isVwoEnabled: boolean;
    banners: IBanners;
    currentPageNodeId: number;
    registrationPlatforms: IRegistrationPlatforms | null;
}

export interface IGtmData {
    gtmId: string;
    userId?: string;
    userSegment?: EnumUserSegment;
    traderLifecyclePosition: number;
    customerLifecyclePosition: number;
    countryMode: string;
    languageMode: string;
    wikifolio: {
        wikifolioShortDescription: string;
        wikifolioIsin: string;
    } | null;
}

export interface INavLink {
    link: ILink;
    children: {
        link: ILink;
        isNew: boolean;
    }[];
}
export interface INav {
    main: INavLink[];
    user: ILink[] | null;
    userSetting: ILink | null;
    logoUrl: string;
}

export type ErrorPageCode = 404 | 500;

export interface IBasePageProps<DataType, DictType> {
    head: IHead;
    footer: IFooter | null;
    global: IGlobal | null;
    user: IUser | null;
    navigation: INav | null;
    data: DataType;
    dict: DictType;
    errorPageCode?: ErrorPageCode | null;
    errorCode?: string;
}
