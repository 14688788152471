import { useEffect } from "react";
import Script from "next/script";
import { useCanRunVwoExperiments } from "src/analytics/use-can-run-vwo-experiments";
import { getGlobals } from "src/consts/globals";
import { VWO_SCRIPT } from "src/consts/script-templates";
import { useCookieBotConsentReady } from "src/hooks/cookie-bot/use-cookie-bot-consent-ready";
import { isServerSide } from "src/utils/common-util";

declare global {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    interface Window {
        VWO: {
            consentState?: number; // Optional property to store the state
            init: (state: 1 | 2 | 3) => void; // init accepts specific number values
        };
    }
}

const HandleVWOInit = () => {
    const isCookieBotConsentReady = useCookieBotConsentReady();

    const canRunVwoExperiments = useCanRunVwoExperiments();

    window.VWO = window.VWO || [];
    window.VWO.init =
        window.VWO.init ||
        function (state) {
            window.VWO.consentState = state;
        };
    // <number-value> can be any of the following:
    // 1 - Accepted
    // 2 - Pending
    // 3 - Denied
    window.VWO.init(2);

    useEffect(() => {
        if (isCookieBotConsentReady) {
            if (canRunVwoExperiments) {
                window.VWO.init(1);
            } else {
                window.VWO.init(2);
            }
        }
    }, [isCookieBotConsentReady, canRunVwoExperiments]);

    return null;
};

export const ThirdPartyScripts = () => {
    const isVwoEnabled = getGlobals()?.isVwoEnabled;
    return (
        <>
            {isVwoEnabled ? (
                <>
                    {isServerSide() ? null : <HandleVWOInit />}
                    <Script strategy="beforeInteractive" id="vwoCode">
                        {VWO_SCRIPT}
                    </Script>
                </>
            ) : null}
        </>
    );
};
