import { AvatarProps, Avatar, Image } from "@chakra-ui/react";
import { BASE_UNDERLYING_LOGO_URL, UNDERLYING_FALLBACK_LOGO_URL } from "src/consts/image-url";

interface IUnderlyingLogoProps extends AvatarProps {
    isin: string | undefined;
}

const UnderlyingLogo = ({ isin, ...props }: IUnderlyingLogoProps) => (
    <Avatar
        icon={<Image src={UNDERLYING_FALLBACK_LOGO_URL} />}
        variant="grayOutline"
        size="sm"
        src={`${BASE_UNDERLYING_LOGO_URL}/${isin?.toUpperCase()}.png`}
        {...props}
    />
);

export { UnderlyingLogo };
